import { Button, Container, Grid, Paper } from "@material-ui/core";
import clsx from 'clsx';
import cookies from "js-cookie";
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";
import { Trans, useTranslation } from 'react-i18next';
import { Prompt, useHistory } from 'react-router';
import DeleteModal from "../../components/delete-modal";
import IconWithHover from "../../components/icon-with-hover";
import Title from "../../components/title";
import UnsavedModal from '../../components/unsaved-modal';
import UpdateTenantHostModal from "../../components/update-tenant-host-modal";
import { AuthContext } from '../../context/authContext';
import api from "../../service/api";
import { getDeploymentProgress, triggerDeployment } from "../../service/deployment-api";
import { request } from "../../service/requests";
import { assignTenantToUser, createTenant, deleteTenantApi } from "../../service/tenant-api";
import { getUserById } from "../../service/user-api";
import { ACTION_UPDATE, ACTION_VIEW, API_REQUEST_ERROR_MESSAGE, DELETE, DEPLOYMENT_ERROR, DEPLOYMENT_ERROR_CODE, DEPLOYMENT_STATUS, GET, PAGES, PATCH, PROMPT_ACTION, SINGLE, STATUS, TENANT, TENANT_ACTION, TENANT_ERROR_KEY, TOASTER_SEVERITY } from "../../utility/constants";
import { getDeploymentError, getDeploymentStatusProgress } from "../../utility/deployment";
import images from "../../utility/images";
import { handlePermissions } from "../../utility/permissions";
import AuditLogComponent from "./audit-log-component";
import BillingDataComponent, { billingPackages } from "./biling-data-component";
import DeploymentProgressComponent from "./deployment-progress-component";
import useStyles from './styles';
import TabComponent from "./tab-component";
import TenantContent from "./tenant-content";
import { TenantSkeleton } from "./tenant-skeleton";
import StatusModal from "./tenant-status-modal";

const Tenant = (props) => {
  const { match, showToaster, showLoading } = props;
  const { t }     = useTranslation();
  const { id }    = match.params;
  const classes   = useStyles();
  const path      = match.path;
  const history   = useHistory();
  const { state } = useContext(AuthContext);

  const { pathname } = history.location;
  
  const { user }  = state;
  
  const hasUpdatePermission = handlePermissions(PAGES.TENANTS, PATCH, user.roles);
  const hasDeletePermission = handlePermissions(PAGES.TENANTS, DELETE, user.roles);

  const defaultAccountManager = useMemo(() => {
    return {
      id   : user.userId,
      name : `${user.firstName} ${user.lastName}`
    }
  }, [user]);

  const language = cookies.get('i18next');

  const [tenantName, setTenantName]                       = useState('');
  const [description, setDescription]                     = useState('');
  const [host, setHost]                                   = useState('');
  const [firstName, setFirstName]                         = useState('');
  const [lastName, setLastName]                           = useState('');
  const [emailAddress, setEmailAddress]                   = useState('');
  const [status, setStatus]                               = useState(STATUS.ACTIVE);
  const [accountManagers, setAccountManagers]             = useState([defaultAccountManager]);
  const [deploymentStatus, setDeploymentStatus]           = useState('');
  const [deploymentError, setDeploymentError]             = useState('');
  const [isLoading, setIsLoading]                         = useState(false);
  const [withChanges, setWithChanges]                     = useState(false);
  const [prevValues, setPrevValues]                       = useState(null);
  const [showModal, setShowModal]                         = useState(false);
  const [statusShowModal, setStatusShowModal]             = useState(false);
  const [showDeleteModal, setShowDeleteModal]             = useState(false);
  const [toRedirect, setToRedirect]                       = useState('');
  const [tabValue, setTabValue]                           = useState(0);
  const [tenant, setTenant]                               = useState(null);
  const [deploymentDescription, setDeploymentDescription] = useState('');
  const [progressNumber, setProgressNumber]               = useState(1);
  const [error, setError]                                 = useState(false);
  const [errorIn, setErrorIn]                             = useState('');
  const [isStatusLoading, setIsStatusLoading]             = useState(false);
  const [isCreated, setIsCreated]                         = useState(false);
  const [isShowChameleon, setIsShowChameleon]             = useState(false);
  const [forDeleteName, setForDeleteName]                 = useState([]);
  const [disableIcon, setDisableIcon]                     = useState(true);
  const [showTenantHostModal, setShowTenantHostModal]     = useState(false);
  const [required, setRequired]                           = useState(false);
  const [plan, setPlan]                                   = useState('');

  const formikReference = useRef();

  const initialValues = useMemo(() => {
    return {
      name                    : tenantName,
      description             : description,
      host                    : host,
      firstName               : firstName,
      lastName                : lastName,
      email                   : emailAddress,
      status                  : status,
      accountManagers         : accountManagers,
      deploymentError         : deploymentError,
      plan                    : plan
    }
  }, [tenantName, description, host, firstName, lastName, emailAddress, status, accountManagers, deploymentError, plan])

  useEffect(() => {
    setPrevValues({
      name           : '',
      description    : '',
      host           : '',
      firstName      : '',
      lastName       : '',
      email          : '',
      status         : STATUS.ACTIVE,
      accountManagers: [],
      deploymentError: '',
      plan           : ''
    })
  }, []);

  const getTenant = useCallback(async () => {
    setIsLoading(true);
    
    let title       = t('tenant-page.error');
    let description = t(API_REQUEST_ERROR_MESSAGE);
    let severity    = TOASTER_SEVERITY.ERROR;

    try {
      const response = await request({
        url    : `${api.TENANTS}/${id}`,
        method : GET
      });
      
      const { data } = response;
      const isStatusActive = data.status === true ? STATUS.ACTIVE : STATUS.DEACTIVATED;
      const formattedAccountManagers = await formatAccountManagers(data.userIds);
      
      const defaultAccountManagers = formattedAccountManagers ? formattedAccountManagers : [];

      const billingPlan = getPlan(data.plan);

      const prevValueData = {
        name           : data.name,
        description    : data.description,
        host           : data.host,
        firstName      : data.firstName,
        lastName       : data.lastName,
        email          : data.email,
        status         : isStatusActive,
        accountManagers: defaultAccountManagers,
        plan           : billingPlan?.plan
      }
      
      setForDeleteName(data.name);
      setPrevValues(prevValueData);
      setTenantName(data.name);
      setDescription(data.description);
      setHost(data.host);
      setStatus(isStatusActive);
      setFirstName(data.firstName);
      setLastName(data.lastName);
      setEmailAddress(data.email);
      setDeploymentStatus(data.deploymentStatus);
      setDeploymentDescription(data.deploymentDescription);
      setDeploymentError(data.deploymentError);
      setTenant(data);
      setPlan(billingPlan?.plan)

      setAccountManagers(defaultAccountManagers);
    } catch (error) {
      showToaster(title, description, severity);
    } finally {
      setIsLoading(false);
    }
  }, [id, showToaster, t]);

  const formatAccountManagers = async (userIds) => {
    const users = await Promise.all(
      userIds.map(async (userId) => {
        const user = await getUserById(userId);

        if (user === null) {
          return;
        }

        return {
          id   : userId,
          name : `${user.firstName} ${user.lastName}`
        }
      })
    );

    return users.filter(value => value !== undefined);
  }

  const getDeploymentStatus = useCallback(() => {
    setIsStatusLoading(true);
    getDeploymentProgress(id).then(data => {
      if (!data || data.error) {
        return;
      }

      const deploymentProgress = getDeploymentStatusProgress(data.status);
      setProgressNumber(deploymentProgress);
    }).finally(() => {
      setIsStatusLoading(false);
    });
  }, [id]);

  useEffect(() => {
    if (id) {
      getTenant();

      //pre-fetch deployment status if still inprogress
      getDeploymentStatus();
    }
  }, [id, getTenant, getDeploymentStatus, pathname]);
  
  const getToasterMessage = (name) => {
    return id ? t('tenant-page.updatedMessage', { name: name }) : t('tenant-page.createdMessage', { name: name });
  }

  const handleSubmit = async (values, formik) => {
    const { setSubmitting, setErrors } = formik;
    const boolStatus = status === STATUS.ACTIVE ? true : false;

    let title       = t('tenant-page.error');
    let description = t(API_REQUEST_ERROR_MESSAGE);
    let severity    = TOASTER_SEVERITY.ERROR;

    if (!withChanges) {
      setSubmitting(false);
      setWithChanges(false);
      if (!path.includes(ACTION_VIEW)) {
        history.push('/tenants');
      }
      return;
    }

    if (plan === '') {
      setRequired(true);
      setSubmitting(false);
      return;
    }

    values.plan = plan;

    try {
      const response = await createTenant(id, deploymentStatus, boolStatus, values);
      const accountManagerIds = accountManagers.map(accountManager => accountManager.id );

      const { _links: links } = response.data;
      const tenantId = links.self.href.replace(`${api.TENANTS}/`, '');
      
      if (prevValues.accountManagers !== accountManagers) {
        await assignTenantToUser(accountManagerIds, tenantId);
      }

      setWithChanges(false);

      title       = t('tenant-page.success');
      description = getToasterMessage(values.name);
      severity    = TOASTER_SEVERITY.SUCCESS;
      
      showToaster(title, description, severity);
      
      setSubmitting(false);
      setIsCreated(true);

      if (path.includes(ACTION_VIEW)) {
        return;
      }

      // Set to `true` if the tenant name has changed and `deploymentError` is `DATABASE_EXISTS`,
      // then update the browser history with the new state for database creation trigger.
      const triggerDatabaseCreation = prevValues?.tenantName !== tenantName 
        && deploymentError === DEPLOYMENT_ERROR_CODE.DATABASE_EXISTS;

      history.push(`/tenants/view/${tenantId}`, { triggerDatabaseCreation });

    } catch (error) {
      if (error.response.status === 409) {
        const { error: key } = error.response.data;
        let errorMessage = {
          name : 'tenant-page.tenantNameAlreadyExist'
        };

        if (key === TENANT_ERROR_KEY.HOST) {
          errorMessage = {
            host: 'tenant-page.tenantHostAlreadyExist'
          }
        } else if (key === TENANT_ERROR_KEY.NAMESPACE) {
          errorMessage = {
            name : 'tenant-page.tenantResourceAlreadyExist'
          };
        }

        setErrors(errorMessage);
      } else {
        showToaster(title, description, severity);
      }
      setSubmitting(false);
      showLoading(false);
      if (formik.isSubmitting) {
        formik.isSubmitting = false;
      }
    }
  }
  
  const handleCloseModal = () => {
    setShowModal(false);
  }

  const handleModalCancel = () => {
    handleChanges();
    setShowModal(false);
  }
  
  const handleModalSubmit = () => {
    setWithChanges(false);
    history.push(toRedirect);
  }
  
  const checkAccountManagerChanges = useCallback(() => {
    if (id) {
      return JSON.stringify(prevValues?.accountManagers) === JSON.stringify(initialValues.accountManagers)
    } else {
      return accountManagers.map(accountManager => accountManager.id).every(id => id === defaultAccountManager.id)
    }
  }, [id, prevValues, initialValues, accountManagers, defaultAccountManager]);

  const handleChanges = useCallback(() => {
    if (prevValues?.firstName === initialValues.firstName
      && prevValues?.lastName === initialValues.lastName
      && prevValues?.email === initialValues.email
      && prevValues?.status === initialValues.status
      && prevValues?.description === initialValues.description
      && prevValues?.host === initialValues.host
      && prevValues?.name === initialValues.name
      && prevValues.plan === initialValues.plan
      && checkAccountManagerChanges()) {
      setWithChanges(false);
    } else {
      setWithChanges(true);
    }
  }, [initialValues, prevValues, checkAccountManagerChanges]);
  
  useEffect(() => {
    handleChanges();
  }, [firstName, lastName, emailAddress, status, accountManagers, description, host, tenantName, handleChanges]);

  const handleChangeTab = (_event, newValue) => {
    setTabValue(newValue);
  }

  const handleCancel = () => {
    history.goBack();
  }
  
  const handleOpenStatusModal = () => {
    setStatusShowModal(true);
  }

  const handleCloseStatusModal = () => {
    setStatusShowModal(false);
  }

  const handleSubmitResponse = async () => {
    const newStatus     = status === STATUS.ACTIVE ? STATUS.DEACTIVATED : STATUS.ACTIVE;
    const booleanStatus = status !== STATUS.ACTIVE;

    const action = booleanStatus ? TENANT_ACTION.ACTIVATE : TENANT_ACTION.DEACTIVATE;
    
    let title       = t('tenant-page.error');
    let description = t(API_REQUEST_ERROR_MESSAGE);
    let severity    = TOASTER_SEVERITY.ERROR;

    setIsLoading(true);
    setStatusShowModal(false);
    
    try {
      await request({
        url    : `${api.TENANTS}/${id}`,
        method : PATCH,
        data   : {
          status : booleanStatus
        },
        headers : {
          "Content-Language" : language,
        }
      });
      
      setStatus(newStatus);
      
      setPrevValues({
        ...prevValues,
        status : newStatus
      });

      title       = t('tenant-page.success');
      description = getStatusMessage(newStatus, tenantName);
      severity    = TOASTER_SEVERITY.SUCCESS;
      
      showToaster(title, description, severity);
    } catch (error) {
      if (error.response.status === 404) {
        description = t('tenant-page.actionError', { action });
        setWithChanges(false);
      }

      showToaster(title, description, severity);
    } finally { 
      setIsLoading(false);
    }
  }

  const getStatusMessage = (newStatus, tenantName) => {
    const toasterMessage = newStatus === STATUS.ACTIVE ? 'status-modal.activatedMessage' : 'status-modal.deactivatedMessage';
    return t(toasterMessage, { item : tenantName });
  }

  const getTitle = () => {
    if (path.includes(ACTION_VIEW)) {
      return <Trans i18nKey='tenant-page.tenantView'/>;
    } else if (path.includes(ACTION_UPDATE)) {
      return <Trans i18nKey={'tenant-page.tenantUpdate'}/>;
    } else {
      return <Trans i18nKey={'tenant-page.tenantCreate'}/>;
    }
  }

  const updateDeploymentProgress = useCallback(() => {
    const deploymentProgress = getDeploymentStatusProgress(deploymentStatus);
    
    //Prevent setting of progress number if pre-fetch data is present
    if (deploymentProgress < progressNumber) {
      return;
    }

    setProgressNumber(deploymentProgress);
  }, [deploymentStatus, progressNumber]);

  const handleSelectChange = (newItems) => {
    setAccountManagers(newItems ? newItems : accountManagers);
  }

  const updateDeploymentError = useCallback(() => {
    setError(true);

    const tenantDeploymentError = getDeploymentError(deploymentError);
    setErrorIn(tenantDeploymentError.error);
    setProgressNumber(tenantDeploymentError.progressNumber);
  }, [deploymentError]);

  const isIconDisabled = useCallback(() => {
    const isDisabled = deploymentError === '' && deploymentStatus !== DEPLOYMENT_STATUS.DEPLOYMENT_FINISHED;
    setDisableIcon(isDisabled);
  }, [deploymentError, deploymentStatus]);

  const handleUpdateClick = () => {
    if (disableIcon) {
      return;
    } else {
      history.push(`../update/${id}`);
    }
  }

  const handleDeleteClick = () => {
    if (disableIcon) {
      return;
    } else {
      setShowDeleteModal(true);
    }
  }

  useEffect(() => {
    if (id) {
      updateDeploymentProgress();
    }
  }, [deploymentStatus, updateDeploymentProgress, id]);

  useEffect(() => {
    if (id && deploymentError) {
      updateDeploymentError();
    }
  }, [deploymentError, updateDeploymentError, id]);

  useEffect(() => {
    isIconDisabled();
    if (id && deploymentError === "" && deploymentStatus !== DEPLOYMENT_STATUS.DEPLOYMENT_FINISHED){
      let timer = setInterval(() => {
        getDeploymentProgress(id).then((data)=> {
          if (!data){
            setError(true);
            setErrorIn(DEPLOYMENT_ERROR.INFRASTRUCTURE_ERROR);
            clearInterval(timer);
            return;
          }
          if (data.error && data.error !== "") {
            setDeploymentError(data.error);
            setDeploymentDescription(data.description);
          } else {
            setDeploymentStatus(data.status);
          }
        });
      }, 3000);
      return () => clearInterval(timer);
    }
  }, [deploymentStatus, updateDeploymentProgress, id, deploymentError, isIconDisabled]);

  const handleDeploymentRetry = () => {
    setError(false);
    setDeploymentDescription('');
    setDeploymentError('');
    setErrorIn('');
    triggerDeployment(tenant?.tenantId ?? id);
  }

  const handleDelete = async () => {
    setIsShowChameleon(true);
    try {
      await deleteTenantApi(id, language);

      showToaster(t('tenant-page.success'), t('tenant-page.tenantRemoveMessage'), TOASTER_SEVERITY.SUCCESS);
      setIsShowChameleon(false);
      history.push('/tenants');
    } catch(error) {
      showToaster(t('tenant-page.error'), t('tenant-page.apiRequestFailed'), TOASTER_SEVERITY.ERROR);
      setIsShowChameleon(false);
    }
  }

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  }

  const displayUpdateModal = (values, formik) => {
    const { host: previousHost } = prevValues;
    if (host !== previousHost && previousHost !== '' ) {
      const { setSubmitting } = formik;
      setSubmitting(false);
      setShowTenantHostModal(true);
      return;
    }

    handleSubmit(values, formik);
  }

  const handleCancelTenantHostModal = () => {
    setShowTenantHostModal(false);
  }

  const handleSubmitTenantHostModal = () => {
    let formik = formikReference.current;
    formik.setSubmitting(true);
    
    handleSubmit(initialValues, formik);
    setShowTenantHostModal(false);
  }

  const getPlan = (name) => {
    return billingPackages.find(billingPackage => billingPackage.plan === name);
  }

  const handlePlanUpdate = () => {
    let formik = formikReference.current;
    formik.setSubmitting(true);
    
    handleSubmit(initialValues, formik);
  }

  let tabContent = '';
  switch (tabValue) {
    case 1:
      tabContent = (
        <AuditLogComponent
          id={id}
          showToaster={showToaster}
          setIsCreated={setIsCreated}
        />
      )
      break;
    case 2:
      const showUpdateButton = prevValues.plan === initialValues.plan ? 'hidden' : classes.action;

      tabContent = (
        <Paper elevation={4} className={classes.billingPaper}>
          <div className={classes.billing}>
            <BillingDataComponent 
              id={id}
              name={tenantName}
              plan={getPlan(plan)} 
              path={path} 
              setPlan={setPlan}
              showToaster={showToaster}
              setIsShowChameleon={setIsShowChameleon}
              previousBillingPlan={prevValues?.plan}
            />
          </div>
          <Grid className={showUpdateButton}>
            <Grid item xs={12}>
              <Button
                className={classes.button}
                onClick={handleCancel}
                variant="outlined"
                color="primary"
                aria-label='cancelButton'
                data-testid='cancelButton'
              >
                <Trans i18nKey={'tenant-page.cancel'}/>
              </Button>
              <Button
                className={classes.button}
                type="button"
                variant="contained"
                color="primary"
                aria-label='submitButton'
                data-testid='submitButton'
                onClick={handlePlanUpdate}
              >
                <Trans i18nKey={'tenant-page.update'}/>
              </Button>
            </Grid>
          </Grid>
        </Paper>
      )
      break;
    default:
      tabContent = (
        <DeploymentProgressComponent
          id={id}
          progressNumber={progressNumber}
          deploymentDescription={deploymentDescription}
          errorIn={errorIn}
          error={error}
          handleDeploymentRetry={handleDeploymentRetry}
          isCreated={isCreated}
          isLoading={isStatusLoading}
          deploymentError={deploymentError}
        />
      )

  }

  return (
    <Container maxWidth="xl" className={classes.container}>
      <UnsavedModal
        open={showModal}
        onClose={handleCloseModal}
        handleModalSubmit={handleModalSubmit}
        handleModalCancel={handleModalCancel}
      />
      <UpdateTenantHostModal
        open={showTenantHostModal}
        onClose={handleCancelTenantHostModal}
        handleModalSubmit={handleSubmitTenantHostModal}
        handleModalCancel={handleCancelTenantHostModal}
      />
      <DeleteModal
        forDeleteName={forDeleteName}
        module={TENANT}
        onClose={handleCloseDeleteModal}
        open={showDeleteModal}
        showToaster={showToaster}
        singleDelete={handleDelete}
        type={SINGLE}
      />
      <Prompt
        when={withChanges}
        message={(location, action) => {
          const updateRedirect = location.pathname.startsWith('/tenants/update');

          if (action === PROMPT_ACTION.PUSH && withChanges && !updateRedirect) {
            setShowModal(true)
          }
          setWithChanges(false);
          setToRedirect(location.pathname);
          return location.pathname === '/' || updateRedirect
        }}
      />
      <StatusModal
        onClose={handleCloseStatusModal}
        open={statusShowModal}
        tenantName={tenantName}
        handleResponse={handleSubmitResponse}
        status={status}
      />
      <Title title={getTitle()} subtitle={tenantName} />
      <Grid container>
        <Grid item xs={12} align="right" className={clsx(!(path.includes(ACTION_VIEW)) && classes.visuallyHidden)}>
          {
            hasUpdatePermission && 
              <IconWithHover
                id="tenantUpdateIcon"
                icon={disableIcon ? images.EDIT_ICON_DISABLED : images.EDIT_ICON}
                hoverIcon={images.EDIT_ICON_HOVER}
                altText="tenant-page.update"
                disabled={disableIcon}
                handleOnClick={handleUpdateClick}
              />
          }
          {
            hasDeletePermission && 
              <IconWithHover
                id="tenantDeleteIcon"
                icon={disableIcon ? images.DELETE_ICON_DISABLED : images.DELETE_ICON}
                hoverIcon={images.DELETE_ICON_HOVER}
                altText="tenant-page.delete"
                disabled={disableIcon}
                handleOnClick={handleDeleteClick}
              />
          }
        </Grid>
      </Grid>
      {
        isLoading ? 
          <TenantSkeleton path={path} />
        : 
          <>
            <TenantContent
              id={id}
              showLoading={showLoading}
              path={path}
              showToaster={showToaster}
              initialValues={initialValues}
              disabled={path.includes(ACTION_VIEW)}
              handleSubmit={displayUpdateModal}
              role={user.roles.name}
              setTenantName={setTenantName}
              setHost={setHost}
              setDescription={setDescription}
              setFirstName={setFirstName}
              setLastName={setLastName}
              setEmail={setEmailAddress}
              handleCancel={handleCancel}
              handleSwitchStatus={handleOpenStatusModal}
              deploymentStatus={deploymentStatus}
              hasUpdatePermission={hasUpdatePermission}
              isShowChameleon={isShowChameleon}
              handleSelectChange={handleSelectChange}
              formikReference={formikReference}
              required={required}
              setPlan={setPlan}
              getPlan={getPlan}
              setIsShowChameleon={setIsShowChameleon}
              previousBillingPlan={prevValues?.plan}
            />
            {
              path.includes(ACTION_VIEW) ?
                <Grid container className={classes.tabView}>
                  <Grid item xs={12}>
                    <TabComponent
                      tabValue={tabValue}
                      handleChangeTab={handleChangeTab}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {tabContent}
                  </Grid>
                </Grid>
              : <></>
            }
          </>
      }
    </Container>
  )
}

export default Tenant;