
import { Box, Button, Paper, Tooltip, Typography } from "@material-ui/core";
import { Check, Info } from "@material-ui/icons";
import clsx from 'clsx';
import cookies from 'js-cookie';
import React, { Fragment, useEffect, useRef, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from 'react-i18next';
import { getBillingData } from "../../../service/tenant-api";
import { ACTION_CREATE, TOASTER_SEVERITY } from "../../../utility/constants";
import { createBillingPackage } from "../../../utility/helper";
import ExportModal from "../export-billing-plan";
import useStyles from './style';

export const billingPackages = [
  createBillingPackage(1, "BASIC", [1, 12, 1, 100], ["locations", "accessPoints", "administrators", "users",]),
  createBillingPackage(2, "PROFESSIONAL", [2, 12, 2, 200], ["locations", "accessPointsPerLocation", "administrators", "users",]),
  createBillingPackage(3, "ENTERPRISE", [10, 12, 10, 1000], ["locations", "accessPointsPerLocation", "administrators", "users",]),
];

const BillingPackage = (props) => {
  const { selectedPackage, handleSelectPackage, getButtonLabel, path } = props;

  const { t }   = useTranslation();
  const classes = useStyles();
  
  return (
    billingPackages.map((billingPackage) => {
      const isSelected = selectedPackage === billingPackage.planId;

      return (
        <Box className={clsx(isSelected ? [classes.selectedPlan] : [classes.plan])}>
          <Box className={classes.gridContainer}>
            <Box className={clsx(isSelected ? [classes.selectedGridItem1] : [classes.gridItem1])}>
              <Box className={classes.boxFlex}>
                <Typography 
                  className={clsx(isSelected ? [classes.selectedPlanTitle] : [classes.planTitle])}>
                    {t(`billing-page.${billingPackage.plan}`.toLowerCase())}
                </Typography>
                <Tooltip
                  title={
                    <Typography align="left">
                      {billingPackage.contents.map((content, index) => (
                        <Fragment key={`${billingPackage.plan}-${index}`}>
                          {`${billingPackage.counts[index]} ${t(`billing-page.${content}`)}`}
                          <br />
                        </Fragment>
                      ))}
                    </Typography>
                  }
                  arrow placement="right-start">
                  {
                    !path.includes(ACTION_CREATE) ?
                      <Info className={isSelected ? classes.iconView : classes.icon}/>
                    : <></>
                  }
                </Tooltip>
                {
                  isSelected && !path.includes(ACTION_CREATE) ?
                    <Typography className={classes.currentPlan}>{t('billing-page.currentPlan')}</Typography>
                  : <></>
                }
              </Box>
              {
                billingPackage.contents.map((content, index) => {
                  return (
                    <>
                      <Box className={clsx(isSelected ? [classes.selectedText] : [classes.text])}>
                        <Check/><Typography>{`${billingPackage.counts[index]} ${t(`billing-page.${content}`)}`}</Typography>
                      </Box>
                    </>
                  )
                })
              }
            </Box>
            <Box/>
            <Box className={clsx(isSelected ? [classes.selectedGridItem2] : [classes.gridItem2])}>
              <Button
                className={clsx(isSelected ? [classes.selectedButton] : [classes.button])}
                variant="contained"
                color="primary"
                onClick={event => handleSelectPackage(event, billingPackage.planId)}
              >
                {getButtonLabel(billingPackage.planId)}
              </Button>
            </Box>
          </Box>
        </Box>
      )
    })
  )
}

const BillingDataComponent = (props) => {
  const { id, plan, name, path, setPlan, showToaster, setIsShowChameleon, required, previousBillingPlan } = props;

  const classes = useStyles();
  const { t }   = useTranslation();
  
  const planId = plan?.planId;

  const [selectedPackage, setSelectedPackage] = useState(planId);
  const [exportShowModal, setExportShowModal] = useState(false);

  const [exportData, setExportData] = useState([]);
  const [fileName, setFileName] = useState('');
  const csvInstance = useRef(null);

  
  const getButtonLabel = (planId) => {
    const prevPlan = billingPackages.find(billingPackage => billingPackage.plan === previousBillingPlan);

    if (prevPlan?.planId === planId) {
      return t('billing-page.currentPlan');
    }

    if (id > 0) {
      return t('billing-page.switchPackage');
    }
    
    if (selectedPackage === planId) {
      return t('billing-page.switchPackage');
    }

    return t('billing-page.buttonSelectPlan');
  }

  const handleSelectPackage = (event, id) => {
    event.stopPropagation();
    
    setSelectedPackage(id);
    const billingPlan = billingPackages.find(billingPackage => billingPackage.planId === id);
    setPlan(billingPlan.plan);
  }

  const handleOpenExportModal = () => {
    setExportShowModal(true);
  }

  const handleCloseExportModal = () => {
    setExportShowModal(false);
  }

  const handleExport = async () => {
    const locale = cookies.get('i18next') || 'en';

    handleCloseExportModal();
    setIsShowChameleon(true);
    
    try {
      const response = await getBillingData(id, locale);
      const contentDisposition = response.headers['content-disposition'];
      
      const csvFileName = contentDisposition.split('filename=')[1].split('.')[0];
  
      const splitData = response.data.split(/\r?\n/).map(data => {
        const dataColumn = data.split(';');
        return [ 
          dataColumn[0],
          dataColumn[1],
          dataColumn[2],
        ]
      })
  
      setExportData(splitData.slice(0, splitData.length-1));
      setFileName(name + csvFileName);
    } catch {
      showToaster(t('tenant-page.error'), t('tenant-page.downloadBillingError'), TOASTER_SEVERITY.ERROR);
    } finally {
      setIsShowChameleon(false);
    }
  }

  useEffect(() => {
    if (exportData.length && csvInstance && csvInstance.current && csvInstance.current.link) {
      setTimeout(() => {
        csvInstance.current.link.click();
        setExportData([]);
      });
    }
  }, [exportData]);

  return (
    <>
      <ExportModal
        onClose={handleCloseExportModal}
        open={exportShowModal}
        handleExport={handleExport}
      />
      {exportData.length ? 
        <CSVLink
          data={exportData}
          ref={csvInstance}
          filename={`${fileName}.csv`}
        />
        : 
        undefined
      }
      <Paper elevation={3}className={id ? classes.paperView : required ? classes.paperRequired : classes.paperCreate}>
        <Box className={classes.planContainer}>
          <BillingPackage
            selectedPackage={selectedPackage}
            handleSelectPackage={handleSelectPackage}
            getButtonLabel={getButtonLabel}
            path={path}
          />
        </Box>
        <Box className={path.includes(ACTION_CREATE) ? 'hidden' : classes.download}>
          <Typography>{t('billing-page.downloadSentence')}</Typography>
          <Button
            disabled={!previousBillingPlan}
            className={classes.downloadButton}
            variant="contained"
            color="primary"
            onClick={(event) => handleOpenExportModal(event)}
          >
            {t('billing-page.downloadButton')}

          </Button>
        </Box>
      </Paper>
    </>
  )
}

export default BillingDataComponent;